<template>
  <div class="deposits-setting">
    <div class="header">
      <div class="style-title-setting-global">
        {{
          $i18n.locale === 'fr' ? 'Gestion des dépôts' : 'Deposit Management'
        }}
      </div>
      <v-btn dark color="#5C2DD3" @click.stop="handleClickBtnAddDepot">
        <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
        {{ $i18n.locale === 'fr' ? 'Ajouter un dépôt' : 'Add deposit' }}
      </v-btn>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="header-team mt-3" v-else>
      <v-list
        dense
        class="list-ensemble mt-3"
        v-if="deposits && deposits.length"
      >
        <v-list-group
          color="#5C2DD3"
          class="list-ensemble-item"
          no-action
          v-for="(deposit, index) in deposits"
          :key="index"
          :class="{
            'border-top-solid': index == 0
          }"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                <div class="font-text font-sz-14 bold-700">
                  {{ deposit.name }}
                </div>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                class="icon-action mr-2"
                small
                outlined
                color="#5C2DD3"
                :title="$t('update')"
                @click.prevent.stop="hundleUpdateClick(deposit)"
              >
                <font-awesome-icon class="img-h-19" icon="pencil-alt" />
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn
                class="icon-action"
                :title="$t('deleted')"
                small
                outlined
                color="#5C2DD3"
                @click.prevent.stop="hundleDeleteClick(deposit)"
              >
                <font-awesome-icon class="img-h-19" icon="trash-alt" />
              </v-btn>
            </v-list-item-action>
          </template>
          <v-card class="card-list border-top-solid">
            <v-list-item>
              <v-list-item-content>
                <div class="mt-2">
                  <strong class="font-text font-sz-13">
                    {{
                      $i18n.locale === 'fr'
                        ? 'Départements : '
                        : 'Departments :'
                    }}</strong
                  >
                  <div>
                    <div
                      v-if="
                        deposit &&
                          deposit.departments &&
                          deposit.departments.data &&
                          deposit.departments.data.length
                      "
                      class="content-members"
                    >
                      <ul class="list-member">
                        <li
                          v-for="item in deposit.departments.data"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.department }}
                        </li>
                      </ul>
                    </div>
                    <div v-else class="color-crm font-text font-sz-12 mt-2">
                      {{
                        $i18n.locale === 'fr'
                          ? 'Aucun département'
                          : 'No department'
                      }}
                    </div>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-list-group>
      </v-list>
      <div v-else class="div-aucun-list">
        <div class="titre">
          {{ $i18n.locale === 'fr' ? 'Aucune dépôt' : 'No deposit' }}
        </div>
      </div>
    </div>
    <!-- Add Deposit Modal -->
    <v-dialog v-model="addDepotsModal" max-width="1200" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale === 'fr' ? 'Ajouter un dépôt' : 'Add deposit' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('addDepotsModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="addDepotsModal" class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="depositToAdd.name"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  type="number"
                  :label="$t('zip_code')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="depositToAdd.zipCode"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[
                    v => !!v || $t('msgObligMsg', { msg: $t('zip_code') })
                  ]"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  :label="
                    $i18n.locale === 'fr' ? 'Numéro dépôt' : 'Deposit number'
                  "
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="depositToAdd.depot_nbr"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('adresse')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="depositToAdd.address"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[
                    v => !!v || $t('msgObligMsg', { msg: $t('adresse') })
                  ]"
                >
                </v-text-field
              ></v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  multiple
                  :placeholder="$t('searchMsg', { msg: $t('department') })"
                  item-color="#5C2DD3"
                  v-model="depositToAdd.departments"
                  :items="departements"
                  :persistent-placeholder="true"
                  chips
                  return-object
                  :deletable-chips="true"
                  :small-chips="true"
                  :label="$t('department')"
                  item-text="department"
                  dense
                  item-value="id"
                  :no-data-text="
                    $t('noDataOptionMsg', { option: $t('department') })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  class="msg-error"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      (v && v.length > 0) ||
                      $t('msgObligMsg', { msg: $t('department') })
                  ]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-if="teamsfiliale && teamsfiliale.length"
                  v-model="depositToAdd.team_id"
                  @change="handleFilialeChange"
                  label="Filiale"
                  dense
                  :no-data-text="$t('noDataOptionMsg', { option: 'filiale' })"
                  item-text="name"
                  item-value="id"
                  :items="teamsfiliale"
                  outlined
                  :persistent-placeholder="true"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      (v && v.length > 0) ||
                      $t('msgObligMsg', { msg: 'Filiale' })
                  ]"
                  class="msg-error"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
                <v-select
                  v-else
                  v-model="depositToAdd.team_id"
                  @change="handleFilialeChange"
                  label="Filiale"
                  dense
                  :no-data-text="$t('noDataOptionMsg', { option: 'filiale' })"
                  item-text="name"
                  item-value="id"
                  :items="teamsfiliale"
                  outlined
                  :persistent-placeholder="true"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      (v && v.length > 0) ||
                      $t('msgObligMsg', { msg: 'Filiale' })
                  ]"
                  class="msg-error"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="depositToAdd.team_id">
              <v-col
                v-if="depositToAdd.team_id"
                cols="6"
                class="scroll-list-filiale"
              >
                <div>
                  {{
                    $i18n.locale === 'fr'
                      ? 'Liste les techniciens'
                      : 'List technicians'
                  }}
                </div>
                <div
                  v-if="getDepotsLoadingTechniciens"
                  class="color-crm font-sz-12 font-text text-center mt-2"
                >
                  {{ $t('loading') }}
                </div>
                <div v-if="technicians && technicians.length">
                  <v-checkbox
                    class="input-checkbox msg-error mt-2 label-check"
                    v-for="(item, index) in technicians"
                    :key="index"
                    v-model="depositToAdd.selectedTechnicians[index]"
                    color="#5C2DD3"
                    hide-details
                  >
                    <template v-slot:label>
                      <div
                        :class="{
                          'icon-exclamation': item.pipe_tech_deleted === 1
                        }"
                        :title="
                          item.pipe_tech_deleted === 1
                            ? $i18n.locale === 'fr'
                              ? 'Technicien supprimé de pipedrive'
                              : 'Technician removed from pipedrive'
                            : ''
                        "
                      >
                        {{ item.first_name }} {{ item.last_name }}
                      </div>
                      <strong
                        class="color-crm font-sz-12 bold-600 ml-2"
                        v-if="item.depot_id != null"
                      >
                        ({{ item.depot.data.name }})
                      </strong>
                    </template>
                  </v-checkbox>
                </div>
                <div
                  v-if="
                    (!technicians || !technicians.length) &&
                      !getDepotsLoadingTechniciens
                  "
                  class="color-crm font-sz-12 font-text text-center mt-2"
                >
                  {{
                    $i18n.locale === 'fr' ? 'Aucun Technicien' : 'No Technician'
                  }}
                </div>
              </v-col>
              <v-col
                v-if="depositToAdd.team_id"
                cols="6"
                class="border-left-solid scroll-list-filiale"
              >
                <div class="">
                  {{
                    $i18n.locale === 'fr'
                      ? 'Liste des commerciaux'
                      : 'List commercials'
                  }}
                </div>
                <div
                  v-if="getDepotsLoadingCommercialITE"
                  class="color-crm font-sz-12 font-text text-center mt-2"
                >
                  {{ $t('loading') }}
                </div>
                <div v-if="commercialIte && commercialIte.length">
                  <v-checkbox
                    class="input-checkbox msg-error mt-2 label-check"
                    v-for="(item, index) in commercialIte"
                    :key="index"
                    v-model="depositToAdd.selectedCommercialIte[index]"
                    color="#5C2DD3"
                    hide-details
                  >
                    <template v-slot:label>
                      <div
                        :class="{
                          'icon-exclamation': item.pipe_tech_deleted === 1
                        }"
                        :title="
                          item.pipe_tech_deleted === 1
                            ? $i18n.locale === 'fr'
                              ? 'Technicien supprimé de pipedrive'
                              : 'Technician removed from pipedrive'
                            : ''
                        "
                      >
                        {{ item.first_name }} {{ item.last_name }}
                      </div>
                      <strong
                        class="color-crm font-sz-12 bold-600 ml-2"
                        v-if="item.depot_id != null"
                      >
                        ({{ item.depot.data.name }})
                      </strong>
                    </template>
                  </v-checkbox>
                </div>
                <div
                  v-if="
                    (!commercialIte || !commercialIte.length) &&
                      !getDepotsLoadingCommercialITE
                  "
                  class="color-crm font-sz-12  font-text text-center mt-2"
                >
                  {{
                    $i18n.locale === 'fr' ? 'Aucun Commercial' : 'No commercial'
                  }}
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="loading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSubmit"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('addDepotsModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Add Deposit Modal -->
    <!-- Update Deposit Modal -->
    <v-dialog v-model="ModalUpdate" max-width="1200" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale === 'fr' ? 'Modifier un dépôt' : 'Edit deposit' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalUpdate')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="loader-content" v-if="loadingOpen">
            <v-progress-circular
              indeterminate
              class="ml-3"
              color="#5C2DD3"
            ></v-progress-circular>
          </div>
          <v-form
            ref="ModalUpdate"
            v-if="depositToUpdate && !loadingOpen"
            class="mt-2"
          >
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="depositToUpdate.name"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  type="number"
                  :label="$t('zip_code')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="depositToUpdate.zipCode"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[
                    v => !!v || $t('msgObligMsg', { msg: $t('zip_code') })
                  ]"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  :label="
                    $i18n.locale === 'fr' ? 'Numéro dépôt' : 'Deposit number'
                  "
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="depositToUpdate.depot_nbr"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('adresse')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="depositToUpdate.address"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[
                    v => !!v || $t('msgObligMsg', { msg: $t('adresse') })
                  ]"
                >
                </v-text-field
              ></v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  multiple
                  :placeholder="$t('searchMsg', { msg: $t('department') })"
                  item-color="#5C2DD3"
                  v-model="selectedDepartementsForUpdate"
                  :items="departmentsForUpdate || []"
                  :persistent-placeholder="true"
                  chips
                  return-object
                  :deletable-chips="true"
                  :small-chips="true"
                  :label="$t('department')"
                  class="mb-0"
                  item-text="department"
                  dense
                  item-value="id"
                  :auto-focus="false"
                  :no-data-text="
                    $t('noDataOptionMsg', { option: $t('department') })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      (v && v.length > 0) ||
                      $t('msgObligMsg', { msg: $t('department') })
                  ]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-if="teamsfiliale && teamsfiliale.length"
                  v-model="depositToUpdate.team_id"
                  @change="handleFilialeChangeUpdate"
                  label="Filiale"
                  dense
                  :no-data-text="$t('noDataOptionMsg', { option: 'filiale' })"
                  item-text="name"
                  item-value="id"
                  :items="teamsfiliale"
                  outlined
                  :persistent-placeholder="true"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      (v && v.length > 0) ||
                      $t('msgObligMsg', { msg: 'Filiale' })
                  ]"
                  class="msg-error"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
                <v-select
                  v-else
                  v-model="depositToUpdate.team_id"
                  @change="handleFilialeChangeUpdate"
                  label="Filiale"
                  dense
                  :no-data-text="$t('noDataOptionMsg', { option: 'filiale' })"
                  item-text="name"
                  item-value="id"
                  :items="teamsfiliale"
                  outlined
                  :persistent-placeholder="true"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      (v && v.length > 0) ||
                      $t('msgObligMsg', { msg: 'Filiale' })
                  ]"
                  class="msg-error"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="depositToUpdate.team_id">
              <v-col class="scroll-list-filiale">
                <div>
                  {{
                    $i18n.locale === 'fr'
                      ? 'Liste les techniciens'
                      : 'List technicians'
                  }}
                </div>

                <div
                  v-if="getDepotsLoadingTechniciens"
                  class="color-crm font-sz-12 font-text"
                >
                  {{ $t('loading') }}
                </div>
                <div v-if="technicians && technicians.length">
                  <v-checkbox
                    class="input-checkbox msg-error mt-2 label-check"
                    v-for="item in technicians"
                    :key="item.id"
                    :value="item.id"
                    v-model="depositToUpdate.selectedTechnicians"
                    color="#5C2DD3"
                    hide-details
                  >
                    <template v-slot:label>
                      <div
                        :class="{
                          'icon-exclamation': item.pipe_tech_deleted === 1
                        }"
                        :title="
                          item.pipe_tech_deleted === 1
                            ? $i18n.locale === 'fr'
                              ? 'Technicien supprimé de pipedrive'
                              : 'Technician removed from pipedrive'
                            : ''
                        "
                      >
                        {{ item.first_name }} {{ item.last_name }}
                      </div>
                      <strong
                        class="color-crm font-sz-12 bold-600 ml-2"
                        v-if="item.depot_id != null"
                      >
                        ({{ item.depot.data.name }})
                      </strong>
                    </template>
                  </v-checkbox>
                </div>
                <div
                  v-if="
                    (!technicians || !technicians.length) &&
                      !getDepotsLoadingTechniciens
                  "
                  class="color-crm font-sz-12 font-text"
                >
                  {{
                    $i18n.locale === 'fr' ? 'Aucun Technicien' : 'No Technician'
                  }}
                </div>
              </v-col>
              <v-col class="scroll-list-filiale">
                <div class="">
                  {{
                    $i18n.locale === 'fr'
                      ? 'Liste des commerciaux'
                      : 'List commercials'
                  }}
                </div>
                <div
                  v-if="getDepotsLoadingCommercialITE"
                  class="color-crm font-sz-12 font-text "
                >
                  {{ $t('loading') }}
                </div>
                <div v-if="commercialIte && commercialIte.length">
                  <v-checkbox
                    class="input-checkbox msg-error mt-2 label-check"
                    v-for="item in commercialIte"
                    :key="item.id"
                    :value="item.id"
                    v-model="depositToUpdate.selectedCommercialIte"
                    color="#5C2DD3"
                    hide-details
                  >
                    <template v-slot:label>
                      <div
                        :class="{
                          'icon-exclamation': item.pipe_tech_deleted === 1
                        }"
                        :title="
                          item.pipe_tech_deleted === 1
                            ? $i18n.locale === 'fr'
                              ? 'Technicien supprimé de pipedrive'
                              : 'Technician removed from pipedrive'
                            : ''
                        "
                      >
                        {{ item.first_name }} {{ item.last_name }}
                      </div>
                      <strong
                        class="color-crm font-sz-12 bold-600 ml-2"
                        v-if="item.depot_id != null"
                      >
                        ({{ item.depot.data.name }})
                      </strong>
                    </template>
                  </v-checkbox>
                </div>
                <div
                  v-if="
                    (!commercialIte || !commercialIte.length) &&
                      !getDepotsLoadingCommercialITE
                  "
                  class="color-crm font-sz-12  font-text"
                >
                  {{
                    $i18n.locale === 'fr' ? 'Aucun Commercial' : 'No commercial'
                  }}
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="loading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSubmitUpdate"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalUpdate')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Update Deposit Modal -->
    <!-- Delete Deposit Modal -->
    <v-dialog v-model="ModalDelete" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr' ? 'Supprimer un dépôt' : 'Delete deposit'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalDelete')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? 'le dépôt' : 'the deposit'
              })
            }}
            <strong> {{ depositToDelete ? depositToDelete.name : '' }}</strong>
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="loading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleModalValider"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalDelete')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Delete Deposit Modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      depositToAdd: {
        name: null,
        zipCode: null,
        address: null,
        departments: [],
        team_id: null,
        technicians: [],
        commercialIte: [],
        selectedTechnicians: [],
        selectedCommercialIte: [],
        departements_id: [],
        depot_nbr: null
      },
      depositToUpdate: null,
      depositToDelete: null,
      loading: false,
      error: null,
      initLoading: true,
      departmentsForUpdate: [],
      selectedDepartementsForUpdate: [],
      addDepotsModal: false,
      ModalUpdate: false,
      ModalDelete: false,
      loadingOpen: false
    }
  },
  methods: {
    ...mapActions([
      'addDepots',
      'getdepartementsDepots',
      'getteamsfiliale',
      'getDepots',
      'postlistTechniciensFilialeDepots',
      'postlistCommercialsFilialeDepots',
      'deleteDepot',
      'postDepartmentsDepotsUpdate',
      'updateDepots'
    ]),
    handleClickBtnAddDepot() {
      this.addDepotsModal = true
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'ModalUpdate') {
        this.$refs.ModalUpdate.resetValidation()
      }
      if (ref == 'addDepotsModal') {
        this.$refs.addDepotsModal.resetValidation()
      }
      this.resetModal()
    },
    validateDepots(deposit) {
      const validate = {
        status: 'success',
        errors: []
      }
      if (!deposit.selectedTechnicians.length) {
        validate.status = 'error'
        validate.errors.push('Le champ de techniciens est obligatoire.')
      }
      if (!deposit.selectedDepartements.length) {
        validate.status = 'error'
        validate.errors.push('Le champ de département est obligatoire.')
      }
      return validate
    },
    async hundleUpdateClick(deposit) {
      this.loadingOpen = true
      this.error = []
      const d = Object.assign({}, deposit)
      if (!d.team) {
        d.team = { data: {} }
      }
      this.depositToUpdate = d
      this.ModalUpdate = true
      if (
        this.depositToUpdate.departments &&
        this.depositToUpdate.departments.data
      ) {
        this.selectedDepartementsForUpdate = this.depositToUpdate.departments.data
      }
      if (this.depositToUpdate.team.data.id) {
        this.postlistTechniciensFilialeDepots({
          team_id: this.depositToUpdate.team.data.id,
          exclude: ['team', 'technicianPipe', 'visibleTeams']
        })
        await this.postlistCommercialsFilialeDepots({
          team_id: this.depositToUpdate.team.data.id,
          exclude: ['team', 'technicianPipe', 'visibleTeams']
        })
      }
      this.depositToUpdate.selectedTechnicians = this.depositToUpdate.poseurs.data.map(
        technicians => {
          return technicians.id
        }
      )
      this.depositToUpdate.selectedCommercialIte = this.depositToUpdate.commercialsIte.data.map(
        commercialIte => {
          return commercialIte.id
        }
      )
      this.depositToUpdate.team_id = this.depositToUpdate.team.data.id
      this.departmentsForUpdate = await this.postDepartmentsDepotsUpdate(
        this.depositToUpdate.id
      )
      this.loadingOpen = false
    },
    hundleDeleteClick(deposit) {
      this.depositToDelete = deposit
      this.ModalDelete = true
    },
    async handleModalValider() {
      this.loading = true
      this.error = null
      const response = await this.deleteDepot(this.depositToDelete.id)
      if (response.success) {
        this.loading = false
        this.getdepartementsDepots()
        this.getteamsfiliale({
          exclude: ['depots', 'manager', 'members']
        })
        this.closeDialog('ModalDelete')
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    async handleFilialeChange() {
      this.depositToAdd.selectedTechnicians = []
      await this.postlistTechniciensFilialeDepots()
      this.postlistTechniciensFilialeDepots({
        team_id: this.depositToAdd.team_id,
        exclude: ['team', 'technicianPipe', 'visibleTeams']
      })
      this.depositToAdd.selectedCommercialIte = []
      this.postlistCommercialsFilialeDepots()
      await this.postlistCommercialsFilialeDepots({
        team_id: this.depositToAdd.team_id,
        exclude: ['team', 'technicianPipe', 'visibleTeams']
      })
    },
    async handleFilialeChangeUpdate() {
      this.depositToUpdate.selectedTechnicians = []
      await this.postlistTechniciensFilialeDepots()
      this.postlistTechniciensFilialeDepots({
        team_id: this.depositToUpdate.team_id,
        exclude: ['team', 'technicianPipe', 'visibleTeams']
      })
      this.depositToUpdate.selectedCommercialIte = []
      this.postlistCommercialsFilialeDepots()
      await this.postlistCommercialsFilialeDepots({
        team_id: this.depositToUpdate.team_id,
        exclude: ['team', 'technicianPipe', 'visibleTeams']
      })
    },
    resetModal() {
      this.depositToAdd = {
        name: null,
        zipCode: null,
        address: null,
        departments: [],
        team_id: null,
        technicians: [],
        commercialIte: [],
        selectedTechnicians: [],
        selectedCommercialIte: [],
        departements_id: [],
        depot_nbr: null
      }
      this.depositToUpdate = null
      this.depositToDelete = null
      this.team_id = []
      this.loading = false
      this.error = null
      this.departmentsForUpdate = []
      this.selectedDepartementsForUpdate = []
      this.postlistTechniciensFilialeDepots()
      this.postlistCommercialsFilialeDepots()
    },
    async handleSubmit() {
      if (this.$refs.addDepotsModal.validate()) {
        const payload = {
          name: this.depositToAdd.name,
          zipCode: this.depositToAdd.zipCode,
          address: this.depositToAdd.address,
          depot_nbr: this.depositToAdd.depot_nbr,
          departments: this.depositToAdd.departments.map(departement => {
            return departement.id
          }),
          team_id: this.depositToAdd.team_id
        }
        if (this.depositToAdd.team_id) {
          const technicians = []
          for (
            let index = 0;
            index < this.depositToAdd.selectedTechnicians.length;
            index++
          ) {
            if (this.depositToAdd.selectedTechnicians[index]) {
              technicians.push(this.technicians[index].id)
            }
          }
          payload.technicians = technicians
        }
        if (this.depositToAdd.team_id) {
          const commercialIte = []
          for (
            let index = 0;
            index < this.depositToAdd.selectedCommercialIte.length;
            index++
          ) {
            if (this.depositToAdd.selectedCommercialIte[index]) {
              commercialIte.push(this.commercialIte[index].id)
            }
          }
          payload.commercialIte = commercialIte
        }
        this.loading = true
        this.error = null
        const response = await this.addDepots(payload)
        if (response.success) {
          this.loading = false
          this.resetModal()
          this.closeDialog('addDepotsModal')
          this.getdepartementsDepots()
          this.getteamsfiliale({
            exclude: ['depots', 'manager', 'members']
          })
        } else {
          this.loading = false
          this.error = response.error
        }
      }
    },
    async handleSubmitUpdate() {
      if (this.$refs.ModalUpdate.validate()) {
        this.error = null
        this.depositToUpdate.selectedDepartements = this.selectedDepartementsForUpdate
        const validate = this.validateDepots(this.depositToUpdate)
        if (validate.status === 'error') {
          this.error = validate.errors
          return
        }
        this.loading = true
        const response = await this.updateDepots(this.depositToUpdate)
        if (response.success) {
          this.loading = false
          this.closeDialog('ModalUpdate')
          this.getdepartementsDepots()
          this.getteamsfiliale({
            exclude: ['depots', 'manager', 'members']
          })
          this.resetModal()
        } else {
          this.loading = false
          this.error = response.error
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'deposits',
      'departements',
      'teamsfiliale',
      'technicians',
      'commercialIte',
      'getDepotsLoadingCommercialITE',
      'getDepotsLoadingTechniciens'
    ])
  },
  async mounted() {
    await this.getDepots({
      exclude: ['accessedByUsers', 'editibleByUsers', 'visibleByUsers']
    })
    this.getdepartementsDepots()
    this.getteamsfiliale({
      exclude: ['depots', 'manager', 'members']
    })
    this.postlistTechniciensFilialeDepots()
    this.postlistCommercialsFilialeDepots()
    this.initLoading = false
  }
}
</script>

<style lang="scss" scoped>
.deposits-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      height: 30px;
      min-width: 30px;
    }
  }
  .list-ensemble-item {
    border-top: 0px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-color: #d8d8d8 !important;
  }
  .list-ensemble {
    max-height: calc(100vh - 209px) !important;
    height: calc(100vh - 209px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .list-ensemble::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .list-ensemble::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .list-ensemble::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
  .icon-exclamation {
    text-decoration: line-through;
    text-decoration-color: #e73e01;
  }
  //   .content-members {
  //     padding-top: 8px;
  //   }
  .list-member {
    margin: 9px 0px;
    li {
      margin-bottom: 6px;
    }
  }
  .card-list {
    box-shadow: unset !important;
  }
}
.icon-exclamation {
  text-decoration: line-through;
  text-decoration-color: #e73e01;
}
.scroll-list-filiale {
  max-height: calc(100vh - 446px) !important;
  height: calc(100vh - 446px) !important;
  margin-bottom: 0;
  overflow-y: auto;
  overflow-x: auto;
  cursor: pointer;
}
.list-ensemble::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.list-ensemble::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 4px;
  background-color: rgba(238, 238, 238, 1) !important;
}
.list-ensemble::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(189, 189, 189, 1);
}
</style>

<style lang="scss">
.deposits-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }
  }
}
.label-check {
  .v-label {
    font-size: 13px !important;
    font-family: 'Montserrat', sans-serif;
  }
}
</style>
